import React, { useEffect } from 'react';
import './cursor.css';

const CursorAnim = () => {
    useEffect(() => {
        const coords = { x: 0, y: 0 };
        const circles = document.querySelectorAll(".circle");
        const textElements = document.querySelectorAll("p, h1, h2, h3, h4, h5, h6, span, a"); // Add more text elements if needed

        const colors = [
            "#400ee0", 
            "#6600d9", 
            "#6600d9", 
            "#8100d1", 
            "#8100d1", 
            "#9500ca", 
            "#9500ca", 
            "#a700c2", 
            "#a700c2", 
            "#b600bb", 
            "#b600bb", 
            "#c300b4", 
            "#c300b4", 
            "#ce00ad", 
            "#ce00ad", 
            "#d718a7", 
            "#d718a7", 
            "#df2aa2", 
            "#df2aa2", 
            "#e63a9d", 
            "#ec4899"
        ];

        circles.forEach(function (circle, index) {
            circle.x = 0;
            circle.y = 0;
            circle.style.backgroundColor = colors[index % colors.length];
        });

        window.addEventListener("mousemove", function(e) {
            coords.x = e.clientX;
            coords.y = e.clientY;
        });

        function animateCircles() {
            let x = coords.x;
            let y = coords.y;

            circles.forEach(function (circle, index) {
                circle.style.left = x - 12 + "px";
                circle.style.top = y - 12 + "px";

                circle.style.transform = `scale(${(circles.length - index) / circles.length})`;

                circle.x = x;
                circle.y = y;

                const nextCircle = circles[index + 1] || circles[0];
                x += (nextCircle.x - x) * 0.4;
                y += (nextCircle.y - y) * 0.4;
            });

            requestAnimationFrame(animateCircles);
        }

        animateCircles();
    }, []);

    return (
        <>
            {Array.from({ length: 20 }).map((_, index) => (
                <div key={index} className="circle"></div>
            ))}
        </>
    );
};

export default CursorAnim;
