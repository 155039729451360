import React, { useEffect } from "react";
import {preLoaderAnim} from '../animations'
import './preloader.css'

const Preloader = () => {

    useEffect(()=>{
        preLoaderAnim()
    },[]);

    return(
        <div className="preloader">
            <div className="texts-container">
                <span>Your Ride,</span>
                <span>Your Fare,</span>
                <span>Our Safety.</span>
            </div>
        </div>
    )
}

export default Preloader