import React, { useEffect, useState } from "react";
import {
  FiArrowRight,
  FiChevronDown,
  FiPieChart,
  FiTrendingUp,
  FiFileText,
} from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { RiTeamLine } from "react-icons/ri";
import { IoBriefcaseOutline } from "react-icons/io5";


export const ShiftingDropDown = () => {
    return (
      <div className="fixed w-full z-50">
        <div className="py-3 flex justify-center w-full bg-[#020617] text-neutral-200 font-varelaround">
          <div className="">
            <Tabs />
          </div>
        </div>
      </div>
    );
  };
    

  const Tabs = () => {
    const [selected, setSelected] = useState(null);
    const [dir, setDir] = useState(null);
  
    const handleSetSelected = (val) => {
      if (typeof selected === "number" && typeof val === "number") {
        setDir(selected > val ? "r" : "l");
      } else if (val === null) {
        setDir(null);
      }
  
      setSelected(val);
    };
  
    return (
      <div
        onMouseLeave={() => handleSetSelected(null)}
        className="relative flex h-fit gap-2"
      >
        {TABS.map((t) => {
          return t.isLink ? (
            <a
              key={t.id}
              href={t.href}
              className="flex items-center gap-1 rounded-full px-3 py-1.5 text-sm text-neutral-400 hover:text-neutral-100 transition-colors"
            >
              <span className="font-varelaround">{t.title}</span>
            </a>
          ) : (
            <Tab
              key={t.id}
              selected={selected}
              handleSetSelected={handleSetSelected}
              tab={t.id}
            >
              {t.title}
            </Tab>
          );
        })}
  
        <AnimatePresence>
          {selected && !TABS.find(t => t.id === selected).isLink && <Content dir={dir} selected={selected} />}
        </AnimatePresence>
      </div>
    );
  };
  

const Tab = ({ children, tab, handleSetSelected, selected }) => {
  return (
    <button
      id={`shift-tab-${tab}`}
      onMouseEnter={() => handleSetSelected(tab)}
      onClick={() => handleSetSelected(tab)}
      className={`flex items-center gap-1 rounded-full px-3 py-1.5 text-sm transition-colors ${
        selected === tab
          ? " bg-[#020617] text-neutral-100"
          : "text-neutral-400"
      }`}
    >
      <span>{children}</span>
      <FiChevronDown
        className={`transition-transform ${
          selected === tab ? "rotate-180" : ""
        }`}
      />
    </button>
  );
};

const Content = ({ selected, dir }) => {
    return (
      <motion.div
        id="overlay-content"
        initial={{
          opacity: 0,
          y: 8,
        }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        exit={{
          opacity: 0,
          y: 8,
        }}
        className="absolute left-0 top-[calc(100%_+_24px)] w-96 rounded-lg border border-[#3A187F] bg-gradient-to-b from-[#010516] to-[#07071F] p-4"
      >
        <Bridge />
        <Nub selected={selected} />
  
        {TABS.map((t) => {
          return (
            <div className="overflow-hidden" key={t.id}>
              {selected === t.id && !t.isLink && (
                <motion.div
                  initial={{
                    opacity: 0,
                    x: dir === "l" ? 100 : dir === "r" ? -100 : 0,
                  }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.25, ease: "easeInOut" }}
                >
                  <t.Component />
                </motion.div>
              )}
            </div>
          );
        })}
      </motion.div>
    );
  };
  
const Bridge = () => (
  <div className="absolute -top-[24px] left-0 right-0 h-[24px]" />
);

const Nub = ({ selected }) => {
  const [left, setLeft] = useState(0);

  useEffect(() => {
    moveNub();
  },//   [selected]
);


  const moveNub = () => {
    if (selected) {
      const hoveredTab = document.getElementById(`shift-tab-${selected}`);
      const overlayContent = document.getElementById("overlay-content");

      if (!hoveredTab || !overlayContent) return;

      const tabRect = hoveredTab.getBoundingClientRect();
      const { left: contentLeft } = overlayContent.getBoundingClientRect();

      const tabCenter = tabRect.left + tabRect.width / 2 - contentLeft;

      setLeft(tabCenter);
    }
  };

  return (
    <motion.span
      style={{
        clipPath: "polygon(0 0, 100% 0, 50% 50%, 0% 100%)",
      }}
      animate={{ left }}
      transition={{ duration: 0.25, ease: "easeInOut" }}
      className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 rounded-tl border border-[#3A187F] bg-[#020617]"
    />
  );
};

const Achievements = () => {
  return (
    <div>
      <div className="flex gap-4">
        <div>
          <h3 className="mb-2 text-sm font-medium">Startup</h3>
        </div>
        <div>
          <h3 className="mb-2 text-sm font-medium">Hackathons</h3>
        </div>
      </div>
    </div>
  );
};

const Activies = () => {
  return (
    <div className="font-varelaround grid grid-cols-3 gap-4 divide-x divide-violet-800">
      
      <a
        href="#"
        className="flex w-full flex-col items-center justify-center py-2 text-neutral-400 transition-colors hover:text-neutral-50"
      >
        <RiTeamLine className="mb-2 text-xl text-violet-200" />
        <span className="text-xs font-varelaround">Founding team</span>
      </a>

      <a
        href="#"
        className="flex w-full flex-col items-center justify-center py-2 text-neutral-400 transition-colors hover:text-neutral-50"
      >
        <FiFileText className="mb-2 text-xl text-violet-200" />
        <span className="text-xs font-varelaround">Our Incubation</span>
      </a>
     
      <a
        href="#"
        className="flex w-full flex-col items-center justify-center py-2 text-neutral-400 transition-colors hover:text-neutral-50"
      >
        <IoBriefcaseOutline className="mb-2 text-xl text-violet-200" />
        <span className="text-xs font-varelaround">Our Interns</span>
      </a>

    </div>
  );
};

const Blog = () => {
  return (
    <div>
      <div className="grid grid-cols-2 gap-2">
        <a href="#">
          <img
            className="mb-2 h-14 w-full rounded object-cover"
            src="/imgs/blog/4.png"
            alt="Placeholder image"
          />
          <h4 className="mb-0.5 text-sm font-medium">Lorem ipsum dolor</h4>
          <p className="text-xs text-neutral-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet illo
            quidem eos.
          </p>
        </a>
        <a href="#">
          <img
            className="mb-2 h-14 w-full rounded object-cover"
            src="/imgs/blog/5.png"
            alt="Placeholder image"
          />
          <h4 className="mb-0.5 text-sm font-medium">Lorem ipsum dolor</h4>
          <p className="text-xs text-neutral-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet illo
            quidem eos.
          </p>
        </a>
      </div>
      <button className="ml-auto mt-4 flex items-center gap-1 text-sm text-indigo-300">
        <span>View more</span>
        <FiArrowRight />
      </button>
    </div>
  );
};

const TABS = [
    {
      title: "Achievements",
      Component: Achievements,
      isLink: true,
      href: "#achievements", // Change href as needed
    },
    {
      title: "Activies",
      Component: Activies,
    },
    {
      title: "Blog",
      Component: Blog,
      isLink: true,
      href: "#blog", // Change href as needed
    },
  ].map((n, idx) => ({ ...n, id: idx + 1 }));
  

export default ShiftingDropDown